@use 'styles/colors' as colors;
@use 'styles/fonts' as fonts;



.toast {
  border-radius: 38px;
  background-color: colors.$core-white;
  border: 2px solid colors.$status-success;
}
.toastWarning {
  border: 2px solid colors.$status-warning;
}
.toastError {
  border: 2px solid colors.$status-error;
}
.toastInfo {
  border: 2px solid colors.$status-info;
}

.toastMessage {
  padding-left: 16px;
}
.toastTitle {
  font-weight: 700;
  line-height: 18px;
}

.toastBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 6px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.toastBtn {
  background-color: colors.$toast-btn-bg;
  border-radius: 50%;
  height: 44px;
  width: 44px;
  border: none;
}
.toastBtnSuccess {
  border: 2px solid colors.$status-success;
  background-color: colors.$toast-btn-success;
}
.toastBtnSuccess:hover{
  background-color: colors.$toast-btn-success-hover;
}
.toastBtnSuccess:active{
  background-color: colors.$toast-btn-success-active;
}
.toastBtnWarning {
  border: 2px solid colors.$status-warning;
  background-color: colors.$toast-btn-warning;
}
.toastBtnWarning:hover {
  background-color:colors.$toast-btn-warning-hover;
}
.toastBtnWarning:active {
  background-color:colors.$toast-btn-warning-active;
}
.toastBtnError {
  border: 2px solid colors.$status-error;
  background-color: colors.$toast-btn-error;
}
.toastBtnError:hover {
  background-color: colors.$toast-btn-error-hover;
}
.toastBtnError:active {
  background-color: colors.$toast-btn-error-active;
}
.toastBtnInfo {
  border: 2px solid colors.$status-info;
  background-color: colors.$toast-btn-info;
}
.toastBtnInfo:hover {
  background-color: colors.$toast-btn-info-hover;
}
.toastBtnInfo:active {
  background-color: colors.$toast-btn-info-active;
}

.progressBarSuccess {
  background: colors.$status-success;
}
.progressBarWarning {
  background: colors.$status-warning;
}
.progressBarError {
  background: colors.$status-error;
}
.progressBarInfo {
  background: colors.$status-info;
}

.shader {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 160px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
  pointer-events: none;
}