@use 'styles/fonts' as fonts;
@use 'styles/colors' as colors;

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 720px;
}

.titleH1 {
  @include fonts.header1;
}

.titleH2 {
  @include fonts.header2;
}

.titleH3 {
  @include fonts.header3;
}

.subH1 {
  @include fonts.subHeader1;
}

.paragraph1 {
  @include fonts.paragraph1;
}

.paragraph2 {
  @include fonts.paragraph2;
}

.paragraph2SemiBold {
  @include fonts.paragraph2SemiBold;
}

.lightHeader {
  color: colors.$f-light-bg-header;
}

.lightParagraph {
  color: colors.$f-light-bg-paragraph;
}

.darkHeader {
  color: colors.$f-dark-bg-header;
}

.darkParagraph {
  color: colors.$f-dark-bg-paragraph;
}

.error {
  color: colors.$status-error;
}

@media only screen and (max-width: 600px) {
  .titleH1 {
    @include fonts.mobileHeader1;
  }

  .titleH2 {
    @include fonts.mobileHeader2;
  }

  .titleH3 {
    @include fonts.mobileHeader3;
  }

  .subheader1 {
    @include fonts.mobileSubHeader1;
  }

  .paragraph1 {
    @include fonts.mobileParagraph1;
  }

  .paragraph2 {
    @include fonts.mobileParagraph2;
  }

  .paragraph2SemiBold {
    @include fonts.mobileParagraph2SemiBold;
  }
}
.copyright {
  @include fonts.copyright;
}
.miniTitle {
  @include fonts.miniTitle;
}
@media screen and (max-width: 480px) {
  .miniTitle {
    font-size: 15px;
  }
}
