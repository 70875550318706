@use 'styles/fonts' as fonts;
@use 'styles/colors' as colors;

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .tali-disable-btn {
    background-image: linear-gradient(
        0deg,
        theme('colors.tali-black-25'),
        theme('colors.tali-black-25')
      ),
      linear-gradient(
        0deg,
        theme('colors.tali-black-50'),
        theme('colors.tali-black-50')
      );
  }
  .tali-btn {
    background-clip: padding-box, border-box;
    @apply rounded-[2rem]
    border border-solid border-transparent
    bg-origin-border
    shadow-tali-submit-btn-default
    hover:shadow-tali-submit-btn-hover hover:translate-y-tali-submit-btn-hover
    active:translate-y-tali-submit-btn-active
    focus:shadow-tali-submit-btn-focus focus:translate-y-tali-submit-btn-focus
    disabled:border disabled:border-solid disabled:border-tali-black-50 disabled:text-tali-black-50 disabled:tali-disable-btn
    disabled:hover:shadow-tali-submit-btn-default disabled:translate-y-tali-submit-btn-disable
    transition-all ease-out;
  }

  .yellow-btn {
    @extend .tali-btn;
    background-image: linear-gradient(279.45deg, #ffd317 7.13%, #ffb40d 92.87%),
      linear-gradient(279.45deg, #ffd317 7.13%, #ffb40d 92.87%);
  }

  .dark-purple-btn {
    @extend .tali-btn;
    background-image: linear-gradient(
        90deg,
        theme('colors.tali-dark-purple'),
        theme('colors.tali-dark-purple')
      ),
      linear-gradient(275.77deg, #ffb40d 4.98%, #a25ecb 26.4%);
  }

  .white-btn {
    @extend .tali-btn;
    background-image: linear-gradient(90deg, white, white),
      linear-gradient(275.77deg, #ffb40d 4.98%, #a25ecb 26.4%);
  }

  .circle-gradient-number {
    border-radius: 50%;
    border: 2px solid transparent;
    background-image: linear-gradient(
        90deg,
        theme('colors.tali-lavendar-50'),
        theme('colors.tali-lavendar-50')
      ),
      linear-gradient(275.77deg, #ffb40d 4.98%, #a25ecb 26.4%);
    background-clip: padding-box, border-box;
    background-origin: border-box;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;900&family=Noto+Serif&display=swap');

html,
body {
  h1 {
    all: unset;
  }
  h2 {
    all: unset;
  }
  h3 {
    all: unset;
  }
  h4 {
    all: unset;
  }
  p {
    max-width: 720px;
  }
  ul {
    max-width: 720px;
  }
  ol {
    max-width: 720px;
  }
  img {
    // Prevent highlighting images during drag selection
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
  padding: 0;
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  background: colors.$core-bg-light;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

#dato {
  h1 {
    @include fonts.header1;
    color: colors.$f-light-bg-header;
  }
  h2 {
    @include fonts.header2;
    margin-top: 96px;
    margin-bottom: 24px;
  }
  h3 {
    @include fonts.header3;
    margin-top: 72px;
    margin-bottom: 24px;
  }
  h4 {
    @include fonts.subHeader1;
    margin-top: 72px;
    margin-bottom: 8px;
  }
  h5 {
    @include fonts.subHeader1;
  }
  h6 {
    @include fonts.paragraph2;
  }
  p {
    @include fonts.paragraph1;
    margin-bottom: 32px;
  }
  a {
    color: colors.$core-mid-purple;
    text-decoration: underline;
    text-decoration-color: colors.$core-mid-purple;
  }
  strong {
    font-weight: 600;
  }

  @media only screen and (max-width: 600px) {
    h1 {
      @include fonts.mobileHeader1;
    }

    h2 {
      @include fonts.mobileHeader2;
    }

    h3 {
      @include fonts.mobileHeader3;
    }

    h4 {
      @include fonts.mobileSubHeader1;
    }

    h5 {
      @include fonts.mobileSubHeader1;
    }

    h6 {
      @include fonts.mobileParagraph2;
    }

    p {
      @include fonts.mobileParagraph1;
    }
  }

  .light {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: colors.$f-light-bg-header;
    }
    p,
    h6 {
      color: colors.$f-light-bg-paragraph;
    }
  }

  .dark {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: colors.$f-dark-bg-paragraph;
    }
    p,
    h6 {
      color: colors.$f-dark-bg-paragraph;
    }
  }
}
